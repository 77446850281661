<template>
  <!-- 合作伙伴 -->
  <div class="content hzbg relative-filter">
    <div class="left-zg"></div>
    <div class="right-zg"></div>
    <div class="box">
      <div class="btop">
        <div class="title">合作伙伴</div>
<!--        <div class="tips">-->
<!--          生态合作伙伴/业务合作伙伴展示,旨在突出合作的价值和卓越的业务能力。-->
<!--        </div>-->
      </div>
      <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper1"
      >
        <!-- <div class="Gtitle">合作伙伴</div> -->
        <div class="swiper-wrapper">
          <div class="imgBox swiper-slide" v-for="item in 14" :key="item">
            <img :src="getItemImg(item)" alt="" />
          </div>
        </div>
      </div>

      <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper2"
      >
        <!-- <div class="Gtitle">合作伙伴</div> -->
        <div class="swiper-wrapper">
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/15.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/16.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/17.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/18.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/19.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/20.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/21.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/22.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/23.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/24.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/25.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/26.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/27.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/28.png" alt="" />
          </div>
        </div>
      </div>

      <div
          class="partner swiper-container swiper-container1"
          id="imgSwiper3"
      >
        <!-- <div class="Gtitle">合作伙伴</div> -->
        <div class="swiper-wrapper">
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/29.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/30.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/31.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/32.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/33.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/34.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/35.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/36.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/37.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/38.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/39.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/40.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/41.png" alt="" />
          </div>
          <div class="imgBox swiper-slide">
            <img src="@/assets/sdImages/home/some/42.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
export default {
  name: "partner",
  data() {
    return {

    }
  },
  mounted() {
    this.initSwiper({el: '#imgSwiper1'})
    this.initSwiper({el: '#imgSwiper2', speed: 2000, reverseDirection: true})
    this.initSwiper({el: '#imgSwiper3'})
  },
  methods: {
    getItemImg(name) {
      return require(`@/assets/sdImages/home/some/${name}.png`)
    },
    initSwiper({el, speed = 3000, reverseDirection = false}) {
      console.log(el)
      new Swiper(el, {
        loop: true, // 开启循环模式
        slidesPerView: 8, // 根据卡片的宽度自动调整每屏显示的卡片数量
        //导航器
        autoplay: {
          //自动播放,不同版本配置方式不同
          delay: 0,
          disableOnInteraction: false,
          reverseDirection, // 反向播放
        },
        freeMode: true,
        speed,
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper-container1 > .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.swiper-container1 {
  overflow: visible;
}

.relative-filter {
  position: relative;
  background-color: #fff;

  .left-zg {
    position: absolute;
    top: 0;
    left: 0;
    width: 240px;
    height: 100%;
    background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
    transform: matrix(-1, 0, 0, 1, 0, 0);
    z-index: 99;
  }

  .right-zg {
    position: absolute;
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    background: linear-gradient(270deg, #fff 29.49%, hsla(0, 0%, 100%, 0));
    z-index: 99;
  }
}

.partner {
  padding: 10px 0;
  background: #fff;

  .imgBox {
    width: 180px;
    height: 82px;
    background: #ffffff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.06);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transform: skewX(-16deg);
    font-size: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    margin: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 172px;
    transform: skewX(15deg);
    width: 128px;
    height: 55px;
    object-fit: cover;
  }

  &-cont {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background: #fff;

    .imgBox {
      width: 180px;
      height: 82px;
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      transform: skewX(-16deg);
      font-size: 0;
      text-align: center;
      position: relative;
      z-index: 1;
      padding: 10px 0;
      margin: 10px;
    }

    img {
      max-width: 172px;
      transform: skewX(15deg);
      width: 80%;
      height: 80%;
    }
  }
}

.content{
  .box {
    width: 1200px;
    margin: 0 auto;
    padding: 65px 0;

    .btop {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0px auto 60px;

      .title {
        // font-size: 32px;
        // font-weight: 500;
        // color: #1D2129;
        // line-height: 45px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 32px;
        color: #1e1e1e;
        line-height: 48px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        // margin-bottom: 13px;
      }

      .tips {
        // font-size: 16px;
        // font-weight: 400;
        // color: #646566;
        // line-height: 22px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #8d8f94;
        line-height: 30px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
</style>
