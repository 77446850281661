<template>
  <div class="page-wrap phone-robot">
    <div class="top-bar">
      <mtop />
    </div>
    <div class="banner">
      <div class="inner">
        <div class="left">
          <div class="title">智慧文旅解决方案</div>
          <div class="desc">通过大数据、人工智能等技术，系统地为目的地、政府、景区、游客等提供基于全流程服务和管理的智慧文旅应用体系。</div>
          <div class="btn" @click="dialogVisible = true, clickMenu('免费试用')">免费试用</div>
        </div>
      </div>
    </div>

    <!-- 背景及痛点 -->
    <div class="section bg-white pain">
      <div class="title">背景及痛点</div>
      <div class="content">
        <div class="list">
          <div class="item" v-for="item in painList" :key="item.title">
            <div class="header">
              <div class="title">{{item.title}}</div>
              <div class="sub-title">{{item.subTitle}}</div>
            </div>
            <div class="info-list">
              <div class="item" v-for="info in item.infoList" :key="info.title">
                <div class="title">{{info.title}}</div>
                <div class="desc">{{info.desc}}</div>
              </div>
            </div>
            <div class="bottom-fixed">
              <div class="header">{{item.pointName}}</div>
              <div class="title">{{item.pointTitle}}</div>
              <div class="content">{{item.pointContent}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 建设思路 -->
    <div class="section plan">
      <div class="title">建设思路</div>
      <div class="content">
        <div class="detail">
          <div class="left">
            <div class="header">
              <img class="icon" src="@/assets/culture-tourism/icon-1.png"/>
              <div class="title">智慧文旅服务平台</div>
            </div>
            <div class="content">
              <p>以积极承接旅游消费释放，全面推进城市旅游升级发展为思考基点，推动地区智慧旅游建设，整合资源、区位、市场优势，打造文化旅游良性循环生态共赢圈。</p>
              <p>贯穿政务大数据和旅游主管部门以及景区的营业数据，推陈出新，引进先进技术，辅助文旅方面的政府决策、促进产业发展、提升游客服务，实现文旅管理科学化、全域服务智能化、文旅营销精准化、多元信息集成化、发展创新多元化和文旅融合一体化的总体目标。</p>
            </div>
          </div>
          <div class="center">
            <div class="list">
              <div class="item">
                <img src="@/assets/culture-tourism/image1.png" />
              </div>
              <div class="item">
                <img src="@/assets/culture-tourism/image2.png" />
              </div>
              <div class="item">
                <img src="@/assets/culture-tourism/image3.png" />
              </div>
            </div>
            <div class="desc">游客市民 旅游平台 第一选择</div>
          </div>
          <div class="right">
            <div class="section-item">
              <div class="header">
                <img class="icon" src="@/assets/culture-tourism/icon-2.png"/>
                <div class="title">服务平台</div>
              </div>
              <div class="content">
                线上旅游官方总入口<br/>
                线上宣推总窗口<br/>
                景区预约总入口<br/>
              </div>
            </div>
            <div class="section-item">
              <div class="header">
                <img class="icon" src="@/assets/culture-tourism/icon-3.png"/>
                <div class="title">管理平台</div>
              </div>
              <div class="content">
                文旅产品订单管理<br/>
                吃住购娱商家管理<br/>
                消费娱乐营销工具
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 总体架构 -->
    <div class="section bg-white arc">
      <div class="title">总体架构</div>
      <div class="content">
        <div class="bg-arc"></div>
      </div>
    </div>

    <!-- 业务框架 -->
    <div class="section biz-frame">
      <div class="title">业务框架</div>
      <div class="content">
        <div class="tab-wrap">
          <div class="tab-header">
            <div
                class="tab-item"
                :class="{active: activeIndex === index}"
                v-for="(item, index) in tabList"
                :key="index"
                @click="activeIndex = index"
            >
              {{item.name}}
            </div>
          </div>
          <div class="tab-body">
            <div class="list">
              <div class="item" v-for="item in tabList[activeIndex].list" :key="item.name">
                <div class="top">
                  <img class="icon" :src="getImage(item)" :alt="item.name" :style="{width: `${item.icon_w}px`, height: `${item.icon_h}px`}" />
                  <span class="title">{{item.name}}</span>
                </div>

                <div class="desc">{{item.desc}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 服务能力：统一接口开放平台 -->
    <div class="section bg-white open-plat">
      <div class="title">服务能力：统一接口开放平台</div>
      <div class="sub-title">统一接口开放平台能为系统提供标准化接口，对接各OTA平台，拓展业务渠道</div>
      <div class="content">
        <div class="bg-plat"></div>
      </div>
    </div>

    <!-- 案例介绍：合肥“和悦游”智慧文旅服务平台建设 -->
<!--    <div class="section case">
      <div class="title">案例介绍：合肥“和悦游”智慧文旅服务平台建设</div>
      <div class="sub-title">合肥和悦游智慧文旅服务平台的开发将成为一个集信息发布、在线预订、支付结算、会员管理、营销工具等功能于一体的综合服务平台，为游客和当地居民提供全方位的旅游和生活服务，也为当地经济和社会带来多方面的效益。 经济价值预估首年750万交易额，逐年进行增长。社会价值预估首年300万用户使用平台，在5年后突破500万会员用户。</div>
      <div class="content">
        <div class="detail">
          <div class="left">
            <div class="title">项目价值</div>
            <div class="list">
              <div class="item">
                <div class="title">1、面向政府</div>
                <div class="desc">建设全市文旅产业运行监测中心，进一步提高全市旅游服务质量和游客满意度。</div>
              </div>
              <div class="item">
                <div class="title">2、面向平台运营管理者</div>
                <div class="desc">建立统一旅游服务门户，并构建基以“运营前置”为目的综合运营管理平台。</div>
              </div>
              <div class="item">
                <div class="title">3、面向广大商家</div>
                <div class="desc">提供手机端商家入驻入口，打造文旅消费市场“引爆点”。</div>
              </div>
              <div class="item">
                <div class="title">4、面向游客</div>
                <div class="desc">建立全市旅游服务总入口，提供行前、行中、行后一站式智慧旅游服务。</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="bg-case"></div>
          </div>
        </div>
      </div>
    </div>-->

    <!-- 合作伙伴 -->
    <partner/>

    <fotterBanner></fotterBanner>
    <mbottom />

    <demonstrate
        :dialogVisible="dialogVisible"
        @closeDialog="dialogVisible = false"
    ></demonstrate>
  </div>
</template>

<script>
import mtop from "@/components/top.vue";
import Partner from "@/components/partner";
import mbottom from "@/components/bottom.vue";
import fotterBanner from "@/components/fotterBanner.vue";
import demonstrate from "@/components/demonstrate.vue";
import {staClickMenu} from "@/utils";
export default {
  components: {
    Partner,
    mtop,
    mbottom,
    fotterBanner,
    demonstrate
  },
  data() {
    return {
      dialogVisible: false,
      activeIndex: 0,
      tabList: [
        {
          name: '智慧文旅服务平台',
          key: '1',
          list: [
            {
              name: '景区门票',
              desc: '线上购票、分时预约',
              icon: 'ywkj-1.png',
              icon_w: 28,
              icon_h: 28
            },
            {
              name: '游线推荐',
              desc: '路线推荐 消费引导',
              icon: 'ywkj-2.png',
              icon_w: 29,
              icon_h: 29
            },
            {
              name: '必吃榜',
              desc: '美食推荐 美食导航',
              icon: 'ywkj-3.png',
              icon_w: 33,
              icon_h: 33
            },
            {
              name: '住在城市',
              desc: '住宿推荐 线上预定',
              icon: 'ywkj-4.png',
              icon_w: 27,
              icon_h: 27
            },
            {
              name: '购在城市',
              desc: '线上购买文创和特产',
              icon: 'ywkj-5.png',
              icon_w: 28,
              icon_h: 28
            },
            {
              name: '玩转城市',
              desc: '导游导览 POI全覆盖',
              icon: 'ywkj-6.png',
              icon_w: 27,
              icon_h: 27
            },
            {
              name: '投诉管理',
              desc: '在线投诉 管理',
              icon: 'ywkj-7.png',
              icon_w: 25,
              icon_h: 25
            },
            {
              name: '内容推送',
              desc: '活动资讯 精品软文',
              icon: 'ywkj-8.png',
              icon_w: 44,
              icon_h: 44
            }
          ]
        },
        {
          name: '智慧文旅服务平台管理台',
          key: '2',
          list: [
            {
              name: '统一门户',
              desc: '统一登录 权限分配',
              icon: 'ywkj-9.png',
              icon_w: 22,
              icon_h: 22
            },
            {
              name: '产品管理',
              desc: '多种类产品统一管理',
              icon: 'ywkj-10.png',
              icon_w: 24,
              icon_h: 24
            },
            {
              name: '销售管理',
              desc: '订单查询 报表生成',
              icon: 'ywkj-11.png',
              icon_w: 23,
              icon_h: 23
            },
            {
              name: '聚合支付',
              desc: '集成多渠道支付',
              icon: 'ywkj-12.png',
              icon_w: 27,
              icon_h: 27
            },
            {
              name: '消费优惠券',
              desc: '会员福利 定期发放',
              icon: 'ywkj-13.png',
              icon_w: 29,
              icon_h: 29
            },
            {
              name: '活动发布',
              desc: '创新联动 产生流量',
              icon: 'ywkj-14.png',
              icon_w: 28,
              icon_h: 28
            },
            {
              name: '景区年卡',
              desc: '景点打包 多次消费',
              icon: 'ywkj-15.png',
              icon_w: 28,
              icon_h: 28
            },
            {
              name: '文旅一码通',
              desc: '一码核销 轻松畅玩',
              icon: 'ywkj-16.png',
              icon_w: 24,
              icon_h: 24
            },
            {
              name: 'B2B管理',
              desc: '覆盖线上线下OTA',
              icon: 'ywkj-17.png',
              icon_w: 26,
              icon_h: 26
            },
            {
              name: '分销平台',
              desc: '代理商销售策略制定',
              icon: 'ywkj-18.png',
              icon_w: 27,
              icon_h: 27
            },
            {
              name: '营销工具',
              desc: '活动、卡券、拼团、秒杀',
              icon: 'ywkj-19.png',
              icon_w: 26,
              icon_h: 26
            }
          ]
        }
      ],
      painList: [
        {
          title: '经济层面 -如何推动文旅经济？',
          subTitle: '缺少产业整合、城市IP建设',
          infoList: [
            {
              title: '促进经济复苏',
              desc: '旅游发展对于经济复苏有促进作用，平台为旅游业提供数字化转型的支持，帮助恢复和提振旅游业的发展'
            },
            {
              title: '推动消费升级',
              desc: '集成了丰富的旅游资源和各类服务功能，包括景区门票、酒店民宿、美食推荐、文创产品。'
            },
            {
              title: '提升城市形象',
              desc: '功能齐全、用户体验良好的智慧文旅旅游服务平台，有助于提升城市形象和知名度。'
            }
          ],
          pointName: '关键点一',
          pointTitle: '促进提升',
          pointContent: '文旅统一服务体系，真正融合需求，整合资源',
        },
        {
          title: '政府层面-如何做好行业监管？',
          subTitle: '缺少产业数据沉淀、难以高效监管',
          infoList: [
            {
              title: '缺少统一门户',
              desc: '游客需要分别下载多个应用或关注多个公众号才能获取相关信息；'
            },
            {
              title: '提高服务效率',
              desc: '各个景区、文博场所信息集中展示，提供在线查询、预订等功能；'
            },
            {
              title: '精准数据统计',
              desc: '一个平台上面了解地区旅游业更加精准、全面的各类数据，为后期的旅游发展决策提供依据。'
            }
          ],
          pointName: '关键点二',
          pointTitle: '高效精准',
          pointContent: '汇聚全域涉旅数据，实现数据多维度分析，赋能行业管理',
        },
        {
          title: '市场层面-如何做大旅游消费？',
          subTitle: '缺少“融合需求”的良性循环',
          infoList: [
            {
              title: '增强竞争力',
              desc: '各景区、文博场所等都在努力提升自身的服务质量和用户体验，实现资源共享和优势互补，提升整体竞争力。'
            },
            {
              title: '拓宽营销渠道',
              desc: '提供全新的营销渠道。通过线上线下结合，扩大地区旅游资源和服务优势。'
            },
            {
              title: '提升用户粘性',
              desc: '集成了多种功能和服务，满足游客的多样化需求。优化和更新内容，可以提高满意度和忠诚度。'
            }
          ],
          pointName: '关键点三',
          pointTitle: '提升拓宽',
          pointContent: '打造旅游大流量入口，扩展异业合同协同，推动地区旅游融合健康发展',
        }
      ]
    }
  },
  methods: {
    getImage(item) {
      return require(`@/assets/culture-tourism/${item.icon}`)
    },
    clickMenu(menuName, url) {
      if (url) {
        this.$router.replace({
          path: url,
        });
      } else {
        if (menuName != '免费试用') {
          this.$toast("敬请期待");
        }
      }
      staClickMenu(menuName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
